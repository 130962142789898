body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.primaryBackgroundColor {
  background: linear-gradient(225deg, #4181f6, #8649f1);
  color: white !important;
}

.alertError {
  background: linear-gradient(90deg, rgba(246,65,65,1) 35%, rgb(239, 42, 42) 100%) !important;
  color: white !important;
}

.alertSuccess {
  background: linear-gradient(90deg, rgb(43, 194, 0) 35%, rgb(28, 190, 37) 100%) !important;
  color: white !important;
}

.alertDiv {
  padding: 10px 10px;
  display: inline-flex;
  font-weight: bold;
}

.iconMargin {
  margin-right: 0.3em;
}

img.logo {
  margin: 4em 0em 1em;
  padding: 1em 1.5em;
  width: 100%;
}

.listItem {
  /*background-color: #56388c !important;*/
  background-color: #7153A8 !important;
  color: white;
  font-weight: bold !important;
  cursor: pointer;
  width: 100%;
  border-radius: 10px;
}

#searchCategoryInput + fieldset, .textFieldCustom + fieldset{
  border-color: #8667BD;
}

.listItemDiv {
  display: block;
  margin: 0.5em 1em;
}

li.listItem:hover {
  background-color: #6A4F9B !important;
}



li.selectedListItem {
  background-color: #A791CF !important;
  color: white;
  font-weight: bold !important;
  cursor: pointer;
  border-radius: 10px;
}


.listItem:hover {
  background-color: #4181f6;
  font-weight: bold !important;
}

.imgCampaigns {
  /*max-width: 200px;  */
  width: 100%;
  height: 110px;
  border-radius: 10px;
}

@media screen and (min-width: 960px){
  .imgCampaigns {
    width: 163px;
    height: 110px;
  }
}

.divCampaign {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
}

.divHiddenCampaign{
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.divHiddenCampaignHide {
  display: none !important;
}

.divHiddenOpacity {
  opacity: 0.5;
  background-color: black;
}

.gridFlexed {
  display: flex;
  flex-direction: column;
  position: relative;
}

.gridFlexed > div {
  margin: 1em;
}

.divHiddenCard {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
}

.containerMultiSelect {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding-bottom: 1em;
  margin: 1em 0;
  border-bottom: 1px solid black;
}


@media only screen and (max-width: 600px){
  .hideOnMobile {
    display: none;
  }
}

@media only screen and (min-width: 600px){
  .hideOnDesktop {
    display: none;
  }
}

.textTransformLowerCase input {
  text-transform: lowercase !important;
}
